/* ==========================================================================
   3. Utilities - These things get used a lot
   ========================================================================== */

/* Hides shit */
.hidden {
    text-indent: -9999px;
    visibility: hidden;
    display: none;
}

/* Creates a responsive wrapper that makes our content scale nicely */
.inner {
    position: relative;
    width: 80%;
    max-width: 700px;
    margin: 0 auto;
}

/* Centres vertically yo. (IE8+) */
.vertical {
    display: table-cell;
    vertical-align: middle;
}