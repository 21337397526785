/* ==========================================================================
   8. Footer - The bottom of every page
   ========================================================================== */

.site-footer {
    position: relative;
    margin: 8rem 0 0 0;
    padding: 4rem 0;
    border-top: #1a1e27 1px solid;
    font-size: 1.3rem;
    line-height: 1.7em;
    color: #BBC7CC;
    text-align: center;
    background: $selection-text-and-header-background;
    a {
        color: #BBC7CC;
        text-decoration: underline;
        &:hover {
            color: #50585D;
        }
    }
}

.poweredby {
    .icon-ghost {
        color: $contessa;
        font-weight: 700;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
        &:before {
            font-size: 1rem;
            margin-right: 0.2em;
        }
    }
}

.subscribe {
    width: 28px;
    height: 28px;
    position: absolute;
    top: -14px;
    left: 50%;
    margin-left: -15px;
    border: #EBF2F6 1px solid;
    text-align: center;
    line-height: 2.4rem;
    border-radius: 50px;
    background: #fff;
    transition: box-shadow 0.5s;
    &:before {
        color: #D2DEE3;
        font-size: 10px;
        position: absolute;
        top: 2px;
        left: 9px;
        font-weight: bold;
        transition: color 0.5s ease;
    }
    &:hover {
        box-shadow: rgba(0,0,0,0.05) 0 0 0 3px;
        transition: box-shadow 0.25s;
        .tooltip {
            opacity: 1;
            top: -33px;
        }
        &:before {
            color: #50585D;
        }
    }
}

.tooltip {
    opacity:0;
    display: inline-block;
    padding: 4px 8px 5px 8px;
    position:absolute;
    top: -23px;
    left: -23px;
    color: rgba(255,255,255,0.9);
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1em;
    text-align: center;
    background: #50585D;
    border-radius:20px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.1);
    transition: opacity 0.3s ease, top 0.3s ease;
    &:after {
        content:"";
        border-width:5px 5px 0 5px;
        border-style:solid;
        border-color: #50585D transparent;
        display:block;
        position:absolute;
        bottom:-4px;
        left:50%;
        margin-left:-5px;
        z-index: 220;
        width:0;
    }
}
