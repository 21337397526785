/* 7.6. Koenig Styles
/* ---------------------------------------------------------- */

.post-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 920px;
}

.post-template .post-content > p:first-child {
    font-size: 1.25em;
    line-height: 1.5em;
}

.post-full-content .kg-image {
    max-width: 100%;
}

/* Preventing full-width image overlap with post image.  */
.post-full-image + .post-full-content .kg-content *:first-child .kg-image {
    width: 100%;
}

.post-full-content .kg-width-wide .kg-image {
    max-width: 1040px;
}

.post-full-content .kg-width-full .kg-image {
    max-width: 100vw;
}

.post-full-content figure {
    margin: 1.5em 0 3em;
}

.post-full-content figure img {
    margin: 0;
}

.post-full-content figcaption {
    margin: 1.0em 0 0;
    font-size: 80%;
    line-height: 1.6em;
    text-align: center;
}

.kg-width-full figcaption {
    padding: 0 1.5em;
}

.kg-embed-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
}

.kg-embed-card .fluid-width-video-wrapper {
    margin: 0;
}


@media (max-width: 1040px) {
    .post-full-content .kg-width-full .kg-image {
        width: 100vw;
    }
}

.kg-gallery-container {
    display: flex;
    flex-direction: column;
    max-width: 1040px;
    width: 100vw;
}

.kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
}

.kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
}

.kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
}

.kg-gallery-card + .kg-image-card.kg-width-wide,
.kg-gallery-card + .kg-gallery-card,
.kg-image-card.kg-width-wide + .kg-gallery-card,
.kg-image-card.kg-width-wide + .kg-image-card.kg-width-wide {
    margin: -2.25em 0 3em;
}


.kg-bookmark-card {
    width: 100%;
    position: relative;
}

.kg-bookmark-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    color: currentColor;
    font-family: inherit;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.kg-bookmark-container:hover {
    text-decoration: none;
}

.kg-bookmark-content {
    flex-basis: 0;
    flex-grow: 999;
    padding: 20px;
    order: 1;
}

.kg-bookmark-title {
    font-weight: 600;
}

.kg-bookmark-metadata,
.kg-bookmark-description {
    margin-top: .5em;
}

.kg-bookmark-metadata {
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.kg-bookmark-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.kg-bookmark-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: text-bottom;
    margin-right: .5em;
    margin-bottom: .05em;
}

.kg-bookmark-thumbnail {
    display: flex;
    flex-basis: 24rem;
    flex-grow: 1;
}

.kg-bookmark-thumbnail img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    object-fit: cover;
}

.kg-bookmark-author {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.kg-bookmark-publisher::before {
    content: "•";
    margin: 0 .5em;
}
