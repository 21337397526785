.site-nav {
    ul {
        padding: 0;
        
        li {
            display: inline-block;
            list-style: none;
        }
    }
    
    .post-header & {
        text-align:  center;
    }
}
