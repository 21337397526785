$text: #B2B6BD;
$background: #2b303b;
$selection-text-and-header-background: #1c212b;
$selection-background-and-link-hover: #a3be8c;
$heading: #739a99;
$border: #424650;

$contessa: #bf6165;
$comet: #4f586b;
$river-bed: #424a5a;

$silver: #ccc;
