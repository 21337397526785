/* ==========================================================================
   1. Icons - Sets up the icon font and respective classes
   ========================================================================== */
@font-face {
    font-family: 'icons';
    src:url('../fonts/icons.eot');
    src:url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icons.woff') format('woff'),
        url('../fonts/icons.ttf') format('truetype'),
        url('../fonts/icons.svg#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

%icon {
    font-family: 'icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}
.icon {
    &-ghost {
        @extend %icon;
        &-text {
            font-family: "Courier New", Courier, monospace;
        }
        &:before {
            content: "\e000";
        }
    }
    &-feed {
        @extend %icon;
        &:before {
            content: "\e000";
        }
    }
}
