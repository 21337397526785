/* ==========================================================================
   4. General - The main styles for the the theme
   ========================================================================== */
#site-head {
    position: relative;
    display: table;
    width: 100%;
    height: 60%;
    margin-bottom: 5rem;
    text-align: center;
    color: #a0add5;
    background: $selection-text-and-header-background no-repeat center center;
    background-size: cover;
}

#blog {
    &-logo {
        width: 100px;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        margin: 0 auto;
        position: relative;
        &:before {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            display: block;
            margin: 0 auto;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: none;
            -moz-transition: background .3s linear;
            -webkit-transition: background .3s linear;
            -o-transition: background .3s linear;
            transition: background .3s linear;
        }
        &:hover:before {
            background: rgba(204,31,39, 0.5);
        }
        img {
            display: block;
            max-height: 100px;
            width: auto;
            line-height: 0;
            border-radius: 50%;
        }
        &-title {
            margin: 10px 0 10px 0;
            font-size: 5rem;
            letter-spacing: -1px;
        }
        &-description {
            margin: 0;
            font-size: 1.8rem;
            line-height: 1.5em;
            font-weight: 300;
            letter-spacing: 0;
        }
    }
}


#back {
    color: rgba(0,0,0,0.2);
    position: fixed;
    top: 8px;
    left: 8px;
    padding: 6px;
    font-size: 16px;
}


/* Every post, on every page, gets this style on its <article> tag */
.post {
    position: relative;
    width:80%;
    max-width: 700px;
    margin: 4rem auto;
    padding-bottom: 4rem;
    border-bottom: $border 1px solid;
    word-break: break-word;
    hyphens: auto;
    &-title {
        color: $heading;
        margin:0;
        a {
            color: $heading !important;
            text-decoration: none;
            &:hover {
                color: lighten($heading, 20%) !important;
            }
        }
    }
    &-excerpt p {
        margin: 1.6rem 0 0 0;
        font-size: 0.9em;
        line-height: 1.6em;
    }
    &-meta {
        display: inline-block;
        margin: 0 0 5px 0;
        font-size: 1.5rem;
        color: #846d8f;
    }
     a {
        color: #846d8f;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: lighten(#846d8f, 10%);
        }
    }

    &-header {
        a {
            color: $contessa;
            transition: color ease 0.3s;
            &:hover {
                color: $selection-background-and-link-hover;
            }
        }
    }
}

.user {
    &-meta {
        position: relative;
        padding: 0.3rem 40px 0 100px;
        min-height: 77px;
    }
    &-image {
        position: absolute;
        top: 0;
        left: 0;
    }
    &-name {
        display: block;
        font-weight: bold;
    }
    &-bio {
        display: block;
        max-width: 440px;
        font-size: 1.4rem;
        line-height: 1.5em;
    }
}


.publish {
    &-meta {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4.3rem 0 4rem 0;
        text-align: right;
    }
    &-heading {
        display: block;
        font-weight: bold;
    }
    &-date {
        display: block;
        font-size: 1.4rem;
        line-height: 1.5em;
    }
}
